import React from "react"
import { FlexboxGrid, Col, Container, Header, Content } from "rsuite"
import ReactPlayer from "react-player"
import renderHTML from "react-render-html"
import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"

import TopHeader from "../components/topHeader"
import CustomMenu from "../components/custommenu"
import FooterSection from "../components/footer"
import SEO from "../components/seo"

import PlayerIcon from "../../static/icons/player_icon.svg"

import "../styles/global.css"

const PAGE_QUERY = gql`
  query PageQuery($uri: String!) {
    pageBy(uri: $uri) {
      title
      content
      acfVideo {
        videopage {
          ... on Page_Acfvideo_Videopage_Videopage {
            videopageDescriptif
            videopageTitre
            videopageNumeroEpisode
            videopageUrlVideo
            videopagePourAllerPlusLoinTitre
            videopagePourAllerPlusLoin {
              ... on Page_Acfvideo_Videopage_Videopage_VideopagePourAllerPlusLoin_VideopagePourAllerPlusLoin {
                videopagePourAllerPlusLoinTexte
                videopagePourAllerPlusLoinUrl
              }
            }
            videopageAutreEpisode {
              ... on Page_Acfvideo_Videopage_Videopage_VideopageAutreEpisode_AutreVideo {
                videopageAutreEpisodeDescription
                videopageAutreEpisodeTitre
                videopageAutreEpisodeNumero
                videopageAutreEpisodeImage {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  }
`

const PageTemplate = props => {
  const uri = props.pageContext.uri
  const { loading, error, data } = useQuery(PAGE_QUERY, { variables: { uri } })
  if (loading) return null
  if (error) return `Error! ${error}`

  const { videopage } = data.pageBy.acfVideo

  const videopageData = videopage.map(page => {
    let data = {
      description: page.videopageDescriptif,
      episode: page.videopageNumeroEpisode,
      titre: page.videopageTitre,
      url: page.videopageUrlVideo,
      pourAllerPlusLoinTitre: page.videopagePourAllerPlusLoinTitre,
      pourAllerPlusLoin: page.videopagePourAllerPlusLoin.map(item => {
        let data = {
          texte: item.videopagePourAllerPlusLoinTexte,
          url: item.videopagePourAllerPlusLoinUrl,
        }
        return data
      }),
      videopageAutreEpisode: page.videopageAutreEpisode.map(item => {
        let data = {
          numero: item.videopageAutreEpisodeNumero,
          titre: item.videopageAutreEpisodeTitre,
          description: item.videopageAutreEpisodeDescription,
          image: item.videopageAutreEpisodeImage.sourceUrl,
        }
        return data
      }),
    }
    return data
  })

  const RenderPAPL = () => {
    let data = []
    videopageData.map(item => {
      return (
        item &&
        item.pourAllerPlusLoin &&
        item.pourAllerPlusLoin.map(items => {
          return data.push({
            question: items.texte,
            url: items.url,
          })
        })
      )
    })
    return data.map((result, i) => (
      <li key={i}>
        <p className="episode-texte-papl">{result.question}</p>
        <a className="episode-url-papl" href={result.url}>
          {result.url}
        </a>
      </li>
    ))
  }

  const renderAutre = videopageData.flatMap(item =>
    item.videopageAutreEpisode.map(item => {
      let data = {
        numero: item.numero,
        titre: item.titre,
        description: item.description,
        image: item.image,
      }
      return data
    })
  )

  const getNextEpisodes = () => {
    return renderAutre.map((item, i) => (
      <FlexboxGrid.Item
        key={i}
        componentClass={Col}
        colspan={24}
        md={5}
        xs={24}
        className="episode"
      >
        <a href={`/episode-${item.numero}`}>
          <p className="player-wrap">
            <img alt="img" src={item.image} width="100%" />
            <img alt="player-icon" src={PlayerIcon} />
          </p>
          <h5 className="autre-episode-titre">{item.titre}</h5>
          <span className="autre-episode-numero">ÉPISODE {item.numero}</span>
          <div className="autre-episode-description">
            {item.description && renderHTML(item.description)}
          </div>
        </a>
      </FlexboxGrid.Item>
    ))
  }

  return (
    <>
      <SEO title="La Série" keywords={[`La Série`]} />
      <Container>
        <Header>
          <div className="block-header">
            <CustomMenu />
            <TopHeader />
          </div>
        </Header>
        <Content id="page-wrap">
          <div className="content">
            <FlexboxGrid justify="center">
              <FlexboxGrid.Item
                componentClass={Col}
                colspan={24}
                md={18}
                xs={24}
              >
                <div className="episode-player">
                  {videopageData[0] && videopageData[0].url && (
                    <ReactPlayer
                      className="react-player-episode"
                      url={videopageData[0].url}
                      width="100%"
                      height="100%"
                    />
                  )}
                </div>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <FlexboxGrid justify="center">
              <FlexboxGrid.Item
                componentClass={Col}
                colspan={24}
                md={18}
                xs={24}
                className="info-episode"
              >
                {videopageData[0] && videopageData[0].titre && (
                  <h2 className="episode-titre">{videopageData[0].titre}</h2>
                )}
                {videopageData[0] && videopageData[0].episode && (
                  <h5 className="episode-numero-episode">
                    ÉPISODE {videopageData[0].episode}
                  </h5>
                )}
                <div className="episode-texte-description">
                  {videopageData[0] &&
                    videopageData[0].description &&
                    renderHTML(videopageData[0].description)}
                </div>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <br />
            <FlexboxGrid justify="center">
              <FlexboxGrid.Item
                componentClass={Col}
                colspan={24}
                md={18}
                xs={24}
                className="wrap-plus-loin"
              >
                <FlexboxGrid justify="center">
                  <FlexboxGrid.Item
                    componentClass={Col}
                    colspan={24}
                    md={24}
                    xs={24}
                    className="faq-footer-title"
                  >
                    {videopageData[0] &&
                      videopageData[0].pourAllerPlusLoinTitre && (
                        <h4 className="episode-titre-papl">
                          {videopageData[0].pourAllerPlusLoinTitre}
                        </h4>
                      )}
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item
                    componentClass={Col}
                    colspan={24}
                    md={12}
                    xs={24}
                    className="faq-footer-link"
                  >
                    <ul>
                      <RenderPAPL />
                    </ul>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item
                    componentClass={Col}
                    colspan={24}
                    md={12}
                    xs={24}
                    className="faq-footer-link"
                  ></FlexboxGrid.Item>
                </FlexboxGrid>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <FlexboxGrid justify="center" className="autres-episode">
              <FlexboxGrid.Item
                componentClass={Col}
                colspan={24}
                md={18}
                xs={24}
              >
                <p className="episode-titre-autre">Voir les autres épisodes</p>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <FlexboxGrid justify="center">
              <FlexboxGrid.Item
                componentClass={Col}
                colspan={24}
                md={18}
                xs={24}
                className=""
              >
                <FlexboxGrid justify="space-between">
                  {getNextEpisodes()}
                </FlexboxGrid>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </div>
        </Content>
        <FooterSection />
      </Container>
    </>
  )
}

export default PageTemplate
